import React,{ useRef, useState } from 'react';
import { useTransition, useSpring, animated, config } from 'react-spring';
import { graphql } from 'gatsby';
import { color } from "@src/settings"
import { useMeasure } from '@components/common/helpers';

import Hero from '@components/en/common/hero';
import Hero__mds from '@components/contact/svg/hero__mds.svg';
import Form from '@components/contact/form';
import Thanks from '@components/contact/thanks';
import Loading from '@components/contact/loading';
import { PageCont, Container, Wrapper, Content, PageIntro, Info } from '@components/contact/styles';

const ContactPage = ({ data }) => {

	const [, setY] = useSpring(() => ({ y: 0 }));
	let isStopped = false;
	const onWheel = () => {
		isStopped = true;
		window.removeEventListener('wheel', onWheel)
	}
	const scrollToTop = () => {
		window.addEventListener('wheel', onWheel);
		setY({
			y: 0,
			reset: true,
			from: { y: window.scrollY },
			onRest: () => {
				isStopped = false;
				window.removeEventListener('wheel', onWheel)
			},
			onFrame: props => {
				if(!isStopped){
					window.scroll(0,props.y)
				}
			}
		})
	}

	const [ formState, setFormState ] = useState("input");

	const [contentRef, { height: contentH }] = useMeasure();

	const timer = (num) => new Promise(resolve => {
		setTimeout(() => {resolve()}, num)
	})

	const springProps = useSpring({
		from: { height: 'auto'},
		to: { height: contentH },
		config: { tenstion: 500, friction: 40 }
	});

	const transitions = useTransition( formState, null, {
		from: { position: 'absolute', opacity: 0 },
		enter: item => async (next, cancel) => {
			scrollToTop();
			await next({ position: 'relative' })
			await timer(600)
			await next({ opacity: 1 })
		},
		leave: item => async (next, cancel) => {
			await next({ position: 'absolute', opacity: 0 })
		},
		config: (item, state) => {
			switch(state){
				case "enter": return { tension: 220, friction: 10 }
				case "leave": return { tension: 400, friction: 20 }
			}
		}
	})

	return (
		<PageCont baseBgColor={ color.paleGray }>
			<main>
				<Hero
					img={ data["heroImg"] }
					title={<Hero__mds />}
				/>
				<Container>
					<Wrapper style={ springProps }>
						<div { ...contentRef }>

						{ transitions.map(({ item, key, props: transProps }) => {
							switch(item){
								case "input":
									return (
										<animated.div style={ transProps } key={ key }>
											<PageIntro
												title={<>Please feel free for any inquiry</>}
												subtitle={<>For inquiries, please contact us using the email form below. <br/> Please fill in the necessary information and press the "Send" button.</>}
											/>
											<Content>
												<Info>
													<h4>LSF TECHNOLOGY</h4>
													<p>Lot 5747, Kidamai Industrial Park,Bukit Angkat,<br/>43000 Kajang, Selangor Darul Ehsan, Malaysia.</p>
													<h5>TEL</h5>
													<p>+60 3 8736 6030</p>
													<h5>FAX</h5>
													<p>+60 3 8738 6030</p>
												</Info>
												<Form
													langEn
													setFormState={ setFormState }
													items={[
															{
																title: "Name",
																name: "name",
																rules: "required",
																isRequired: true,
															},
															{
																title: "Company Name",
																name: "company",
																isRequired: false,
															},
															{
																title: "Email",
																name: "email",
																rules: "required|email",
																isRequired: true,
															},
															{
																title: "Confirm Email",
																name: "email_confirm",
																rules: "required|same:email",
																isRequired: true,
															},
															{
																title: "Inquiry",
																name: "inquiry",
																rules: "required",
																as: "textarea",
																isRequired: true,
															}
														]}
													btnLabel="Send"
												/>
											</Content>
										</animated.div>
									);
									break;
								case "sending":
									return (
										<animated.div style={ transProps } key={ key }>
											<Loading />
										</animated.div>
									);
									break;
								case "done":
									return (
										<animated.div style={ transProps } key={ key }>
											<Thanks
												title="Thank you for your inquiry"
												desc={<>The transmission is complete. <br /> Please check the email for confirmation sent to the email address you entered. <br /> A representative will contact you within a few days.</>}
											/>
										</animated.div>
									);
									break;
							}
						})}
						</div>
					</Wrapper>
				</Container>
			</main>
		</PageCont>
	)
}


export const query= graphql`
	query ContactImgs_en{
		
		heroImg: file( relativePath: { eq: "contact/hero-img_200414.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

	},
`;

export default ContactPage
